// src/components/Contact.js
import React from 'react';
import './Contact.css'; // Import the CSS file

function Contact() {
  return (
    <div className="contact-container">
      <h2>Contact</h2>
      <p>If you want to get in touch, you can reach me through the following channels:</p>
      <div className="contact-info">
        <ul>
          <li>
            <a href="mailto:anirudhdilli@gmail.com">
              <span className="icon">📧</span>
              Email: anirudhdilli@gmail.com
            </a>
          </li>
          <li>
            <a href="https://discord.com/users/976398366823039026" target="_blank" rel="noopener noreferrer">
              <span className="icon">💬</span>
              Discord: VainXploits
            </a>
          </li>
          <li>
            <a href="https://github.com/VainXploits" target="_blank" rel="noopener noreferrer">
              <span className="icon">🐙</span>
              GitHub: VainXploits
            </a>
          </li>
          <li>
            <a href="https://app.hackthebox.com/profile/400681" target="_blank" rel="noopener noreferrer">
              <span className="icon">💻</span>
              HackTheBox: VainXploits
            </a>
          </li>
          <li>
            <a href="https://tryhackme.com/p/VainXploits" target="_blank" rel="noopener noreferrer">
              <span className="icon">🔍</span>
              TryHackMe: VainXploits
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Contact;
