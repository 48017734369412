// src/components/About.js

import React from 'react';
import './About.css';

function About() {
  return (
    <section className="about">
      <h2>About Me</h2>
      <p>
        Hi, I’m Vain, a passionate cybersecurity enthusiast with a deep interest in the art of safeguarding digital landscapes. 
        With hands-on experience in penetration testing and website development, I thrive on uncovering vulnerabilities and enhancing security protocols.
      </p>
      <p>
        My journey in cybersecurity started with a fascination for how systems operate and how they can be exploited. Over the years, 
        I have developed a robust skill set in penetration testing, allowing me to identify and address security flaws effectively. 
        Alongside, I’ve honed my abilities in website development, understanding the intricate details of web security and the importance of building secure applications.
      </p>
      <p>
        I am also well-versed in Python, shell scripting, and C, which have greatly enhanced my ability to develop and automate security solutions.
        Recently, I’ve been collaborating with friends on some exciting new tools that will be released in the future. Stay tuned for more updates!
      </p>
      <p>
        My background in history enriches my approach to cybersecurity, providing me with a unique perspective on the evolution of technology and 
        the broader context of cyber threats. This diverse skill set fuels my drive to stay ahead of emerging threats and continually improve my expertise.
      </p>
      <p>
        Feel free to connect with me through my social media profiles or drop me an email to discuss cybersecurity trends, best practices, or just to chat about the latest in tech.
      </p>
    </section>
  );
}

export default About;
