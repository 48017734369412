// src/components/ProjectDetail.js

import React from 'react';
import { useParams } from 'react-router-dom';
import searchsploitImg from '../assets/projects/unigui/searchsploit.png';
import scanningImg from '../assets/projects/unigui/scanning.png';
import ffufImg from '../assets/projects/unigui/ffuf.png';
import fluffclanImg from '../assets/projects/fluffclan/fluffclan.png'; // Make sure to add this image to the correct path
import server1 from '../assets/projects/serveruptime/server1.png';
import './ProjectDetail.css';

const projectDetails = {
  unigui: {
    title: 'UniGui',
    description: 'UniGui is an all-in-one cybersecurity tool designed to streamline and enhance the efficiency of SOC teams, offensive security teams, and CTF players.',
    imgSrcs: [
      searchsploitImg,
      scanningImg,
      ffufImg,
    ],
    features: [
      'Target Management: Easily add and manage multiple targets.',
      'Workflow Automation: Automate repetitive security tasks and processes.',
      'Customizable Workflows: Define and customize workflows based on specific needs.',
      'Real-Time Monitoring: Monitor security operations in real-time with integrated dashboards.',
      'Reports & Alerts: Generate detailed reports and receive alerts based on predefined conditions.',
    ],
    developmentProcess: 'UniGui was developed with a focus on flexibility and usability. The initial version was created to address common pain points faced by SOC teams. Subsequent updates have focused on enhancing usability and integrating new features based on user feedback.',
    futurePlans: 'Future updates for UniGui include integrating machine learning algorithms for predictive analytics, expanding integration with other security tools, and improving the user interface for better accessibility.',
    moreInfo: 'UniGui is designed to be a versatile tool for security professionals, with a strong emphasis on customization and automation. It aims to simplify the security operations workflow and improve overall efficiency.',
  },
  fluffclan: {
    title: 'FluffClan.com',
    description: 'FluffClan.com celebrates fluffme, a well-loved moderator on the TryHackMe Discord, and highlights the community culture where members add `| fluffclan` to their usernames for fun. Make sure to visit <a href="https://fluffclan.com" target="_blank" rel="noopener noreferrer">FluffClan</a> to find out more!',
    imgSrcs: [
      fluffclanImg, // Add more images if available
    ],
    features: [
      'Community Celebration: Showcases the fun and engaging culture of the TryHackMe Discord community.',
      'Username Flair: Highlights the ongoing fun where members add `| fluffclan` to their usernames.',
      'Interactive Content: Features content that reflects the spirit and humor of the community.',
    ],
    developmentProcess: 'FluffClan.com was developed to capture the fun and engaging aspects of the TryHackMe community, focusing on highlighting fluffme’s positive influence and the unique community culture. The website aims to bring members together and celebrate their shared camaraderie.',
    futurePlans: 'Future updates may include interactive features to further engage the community and celebrate additional aspects of the FluffClan culture.',
    moreInfo: 'FluffClan.com is a fun project designed to celebrate a beloved moderator and the unique culture of the TryHackMe Discord community. It serves as a lighthearted space for members to connect and enjoy shared jokes.',
  },
  cheesectf: {
    title: 'CheeseCTF',
    description: 'CheeseCTF is a custom-built Capture The Flag (CTF) environment that I designed to highlight my skills in vulnerability assessment and offensive security. It’s an interactive learning experience where participants can practice identifying and exploiting security flaws.',
    imgSrcs: [], // Initialize as an empty array to avoid the error
    features: [
      'In-Depth Vulnerability Assessment: I crafted scenarios that guide users through comprehensive scans to uncover system weaknesses.',
      'Exploit Development: I developed custom exploits tailored to the vulnerabilities within the challenges, allowing participants to see the real impact of security flaws.',
      'Interactive CTF Challenges: The room features a series of realistic attack scenarios that simulate real-world cybersecurity threats.',
      'Guided Walkthroughs: Each challenge includes detailed walkthroughs to help users understand the methodology behind each exploit.',
      'Custom Reports: Participants can generate detailed reports based on their findings, reflecting the steps taken during their assessments.',
    ],
    developmentProcess: 'CheeseCTF was a passion project that I developed to combine my interest in offensive security with practical learning. The challenges were carefully designed to cover a range of vulnerabilities, with a strong focus on hands-on experience.',
    futurePlans: 'I plan to expand CheeseCTF with additional challenges that cover emerging security threats, incorporate automated exploit testing, and enhance the overall user experience with more interactive elements.',
    moreInfo: 'CheeseCTF is a reflection of my dedication to cybersecurity. It’s designed for anyone looking to deepen their understanding of vulnerability assessment and exploit development through engaging, real-world scenarios.',

  },
  serverUptimeProject: {
    title: 'Server Uptime Optimization',
    description: 'A dynamic server management system that ensured 100% uptime by balancing user and bot counts across two servers.',
    imgSrcs: [
      server1,
    ],
    features: [
      'User-Bot Balance: Maintained a minimum of 75 active users out of 100 across two servers.',
      'Uptime Guarantee: Ensured 100% uptime through dynamic load balancing.',
      'Revenue Impact: Increased monthly revenue by $13,000, totaling $156,000 annually.',
      'Top Server Utilization: Ranked consistently in the top 5 most utilized servers.',
    ],
    developmentProcess: 'This project was developed with a focus on maximizing server uptime and optimizing user experience. Dynamic balancing algorithms were implemented to maintain a healthy ratio of active users, preventing server downtime.',
    moreInfo: 'This system is designed to maintain high availability, ensuring smooth operations and improving the financial viability of server management.',
},

};

function ProjectDetail() {
  const { projectId } = useParams();
  const project = projectDetails[projectId];

  if (!project) return <div>Project not found</div>;

  return (
    <section className="project-detail">
      <h1>{project.title}</h1>
      <div className="project-gallery">
        {project.imgSrcs.map((imgSrc, index) => (
          <a key={index} href={imgSrc} target="_blank" rel="noopener noreferrer">
            <img src={imgSrc} alt={`${project.title} Screenshot ${index + 1}`} className="project-image" />
          </a>
        ))}
      </div>
      <div dangerouslySetInnerHTML={{ __html: project.description }}></div>
      
      {project.features && (
        <section className="project-features">
          <h2>Features</h2>
          <ul>
            {project.features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </section>
      )}
      
      {project.developmentProcess && (
        <section className="project-development-process">
          <h2>Development Process</h2>
          <p>{project.developmentProcess}</p>
        </section>
      )}
      
      {project.futurePlans && (
        <section className="project-future-plans">
          <h2>Future Plans</h2>
          <p>{project.futurePlans}</p>
        </section>
      )}
      
      {project.moreInfo && (
        <section className="project-more-info">
          <h2>Additional Information</h2>
          <p>{project.moreInfo}</p>
        </section>
      )}
    </section>
  );
}

export default ProjectDetail;
