import React from 'react';
import './Blog.css';

function Blog() {
  return (
    <section className="blog">
      <div className="blog-content">
        <h2 className="blog-heading">Blog</h2>
        <p className="coming-soon">Coming Soon!</p>
      </div>
    </section>
  );
}

export default Blog;
