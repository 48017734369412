// src/components/Projects.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Projects.css';

const projects = [
  {
    id: 'unigui',
    title: 'UniGui',
    description: 'UniGui is an all-in-one cybersecurity tool designed to streamline and enhance the efficiency of SOC teams, offensive security teams, and CTF players.',
  },
  {
    id: 'fluffclan',
    title: 'FluffClan',
    description: 'FluffClan.com celebrates fluffme, a well-loved moderator on the TryHackMe Discord, and highlights the community culture where members add `| fluffclan` to their usernames for fun.',
  },
  {
    id: 'cheesectf',
    title: 'CheeseCTF',
    description: 'CheeseCTF is a custom-built Capture The Flag (CTF) environment that I designed to highlight my skills in vulnerability assessment and offensive security. It’s an interactive learning experience where participants can practice identifying and exploiting security flaws.',
  },{
    id: 'serverUptimeProject',
    title: 'Maintaining Server Uptime!',
    description: 'A dynamic server management system that ensured 100% uptime by balancing user and bot counts across two servers.',
  },
  // Add more projects here as they become available
];

function Projects() {
  return (
    <section className="projects">
      <h2>Projects</h2>
      <div className="project-list">
        {projects.map((project) => (
          <div key={project.id} className="project-card">
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <Link to={`/projects/${project.id}`} className="learn-more-link">Learn More</Link>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Projects;
